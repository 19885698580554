import { combineReducers } from 'redux';

import files from './fileReducer';
import auth from './authReducer';
import types from './typesReducer';
import status from './statusReducer';
import categories from './categoriesReducer';

export default combineReducers({
    files,
    auth,
    types,
    categories,
    status,
});