
import { status } from '../api';

export const listStatus = (callback) => {
    const res = status.list()

    res.then(() => {
        if (callback) callback();
    });

    return {
        type: 'GET_STATUS',
        payload: res,
    };
}