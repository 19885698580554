const approveMiddleware = store => next => action => {
    // if (action.type === 'APPROVE_FILE_FULFILLED' || action.type === 'REJECT_FILE_FULFILLED' || action.type === 'APPROVE_FILE_REJECTED' || action.type === 'REJECT_FILE_REJECTED') {
    //     console.log(action, 'action');
    // }


    next(action);
}


export default approveMiddleware;