import { getUidFromJWT } from 'helpers/authHelpers';
import { files, me } from '../api';

export const loadMyPendingFiles = (page = 1, perPage) => {
    const res = me.getMyPending({ page, perPage })
    return {
        type: 'GET_PENDING_FILES',
        payload: res,
    }
}

export const cleanFileStore = () => {
    return {
        type: 'CLEAN_STORE',
        payload: undefined,
    };
}

export const approveFile = (file, comment, callback) => {
    const res = files.approveFile({ id: file.id, comment });

    res.then(() => {
        if (callback) callback();
    });

    return {
        type: 'APPROVE_FILE',
        payload: res,
    };
}

export const rejectFile = (file, comment, callback) => {
    const res = files.rejectFile({ id: file.id, comment })

    res.then(() => {
        if (callback) callback();
    });

    return {
        type: 'REJECT_FILE',
        payload: res,
    };
}

export const listArchive = (status, type, category, search, callback, perPage, page) => {
    const res = files.listArchive({ status, type, category, search, per_page: perPage, page })
    if (res) {
        res.then(() => {
            if (callback) callback();
        });
    }

    return {
        type: 'GET_FILES',
        payload: res,
    };
}

export const setError = (username, password) => {

}
