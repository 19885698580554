import React, { useEffect, useState } from 'react';
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import PDFViewer from 'components/PDFViewer/PDFViewer';
import { TextareaAutosize } from '@material-ui/core';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const dialogClasses = {
    root: {

    },
    paper: {

    },
    "MuiDialogContent-root": {
        "overflow-y": 'scroll'
    },
    modalFooter: {
        padding: '35px 30px',
        margin: 'auto',
        width: '70% !important'
    },
    modalHeader: {
        padding: '12px 24px 0px 24px',
    },
    modalCloseButton: {
        position: 'absolute !important',
        width: '55px !important',
        height: '55px !important',
        top: '20px',
        right: '40px',
        "& .MuiButton-label": {
            height: '100% !important',
            "& .MuiSvgIcon-root": {
                width: '90%',
                height: '90%'
            }
        },
    },
    commentTextArea: {
        width: '80%',
        resize: 'none',
        marginRight: '30px',
        borderRadius: '5px'
    },
    inputGroup: {
        width: '100%',
    },
    errorMessage: {
        color: 'red',
        display: 'block',
        position: 'absolute'
    }

};
const useStyles = makeStyles(dialogClasses);

export default function DocumentInspector({ isOpen, closeInspector, file, approveFile, rejectFile, company }) {
    const [pdfFile, setPdfFile] = useState();
    const [comment, setComment] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (file) {
            fetch(file.address, {
                method: 'GET',
                headers:
                {
                    "Access-Control-Allow-Origin": '*',
                    "Access-Control-Allow-Headers": '*'
                },
            }).then((res) => res.blob()).then(body => {
                const reader = new FileReader();
                reader.readAsDataURL(body);
                reader.onload = () => {
                    const base64data = reader.result;
                    setPdfFile(base64data);
                }
            })
        }
    }, [file])


    const rejectHandler = () => {
        if (!comment && company.requiredRejectComment) {
            setError('Unesite vas komentar prije nego odbijete dokument!');
        } else {
            rejectFile(file, comment);
            setComment('');
            setError('');
        }
    }

    const approveHandler = () => {
        if (!comment && company.requiredApproveComment) {
            setError('Unesite vas komentar prije nego odobrite dokument!');
        } else {
            approveFile(file, comment);
            setComment('');
            setError('');
        }
    }

    const commentChangeHandler = (e) => {
        setComment(e.target.value);
    }

    const handleClose = (e) => {
        setError('');
        setComment('');
        setPdfFile(undefined);
        closeInspector(e);
    }

    const classes = useStyles();
    return (
        <div>
            <Dialog
                fullScreen
                fullWidth
                classes={dialogClasses}
                open={isOpen}
                transition={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <h3>{file?.name}</h3>
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={handleClose}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>{ }</h4>
                </DialogTitle>
                <DialogContent
                    id="modal-slide-description"
                    className={classes.modalBody}
                >
                    <PDFViewer file={pdfFile} />
                </DialogContent>
                {(rejectFile || approveFile) && <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}
                >
                    <div className={classes.inputGroup} >
                        <TextareaAutosize value={comment} onChange={commentChangeHandler} rowsMin={3} rowsMax={3} aria-label="empty textarea" placeholder="Dodajte Vaš komentar.." className={classes.commentTextArea} />
                        <span className={classes.errorMessage}>{error}</span>
                    </div>

                    {rejectFile && <Button onClick={rejectHandler}>Odbij</Button>}
                    {approveFile && <Button onClick={approveHandler} color="success">
                        Odobri
                    </Button>}
                </DialogActions>}
            </Dialog>
        </div>
    );
}