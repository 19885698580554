// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components/views for Admin layout
import Login from "views/Login/Login.js";
import MyPending from "views/MyPending/MyPending.js";
import Documents from "views/Documents/Documents.js";
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import HistoryIcon from '@material-ui/icons/History';

const dashboardRoutes = [
  {
    path: "/login",
    name: "Log in",
    component: Login,
    layout: "",
    exact: true
  },
  {
    path: "/my-pending",
    name: "Dokumenti na čekanju",
    subtitle: 'U ovoj sekciji možete pronaći sve Vama dodijeljene dokumente koji čekaju pregled',
    icon: MarkunreadMailboxIcon,
    component: MyPending,
    layout: "",
    exact: true,
    showInSidebar: true
  },
  {
    path: "/files",
    name: "Arhiva",
    subtitle: 'U ovoj sekciji možete pronaći sve dostupne dokumente unutar Vaše kompanije',
    icon: HistoryIcon,
    component: Documents,
    layout: "",
    exact: true,
    showInSidebar: true
  },
];

export default dashboardRoutes;
