const initalState = {
    request: { pending: false, error: false, fulfilled: false, loading: false },
    filesData: null,
    pagination: null,
    selectedFile: undefined,
    errors: {},
};

export default function reducer(state = initalState, action) {
    let pag = null;
    switch (action.type) {
        /* GET PENDING DOCS */
        case 'GET_PENDING_FILES_PENDING':
            return {
                ...state,
                request: { ...state.request, pending: true, error: false, fulfilled: false, loading: false }
            };
        case 'GET_PENDING_FILES_REJECTED':
            return {
                ...state,
                request: { ...state.request, pending: false, error: true, fulfilled: false, loading: false },
                filesData: [],
                pagination: null
            };
        case 'GET_PENDING_FILES_FULFILLED':
            pag = action.payload?.data ? { ...action.payload } : null;
            if (!pag) {
                delete pag.data;
            }
            return {
                ...state,
                request: { ...state.request, pending: false, error: false, fulfilled: true, loading: false },
                filesData: action.payload?.data ? action.payload.data : action.payload,
                pagination: pag
            }
        /* GET PENDING DOCS END */
        /* GET PENDING DOCS */
        case 'GET_FILES_PENDING':
            return {
                ...state,
                request: { ...state.request, pending: true, error: false, fulfilled: false, loading: false }
            };
        case 'GET_FILES_REJECTED':
            return {
                ...state,
                request: { ...state.request, pending: false, error: true, fulfilled: false, loading: false },
                filesData: [],
                pagination: null
            };
        case 'GET_FILES_FULFILLED':
            pag = action.payload?.data ? { ...action.payload } : null;
            if (!pag) {
                delete pag.data;
            }
            return {
                ...state,
                request: { ...state.request, pending: false, error: false, fulfilled: true, loading: false },
                filesData: action.payload?.data ? action.payload.data : action.payload,
                pagination: pag
            }
        /* GET PENDING DOCS END */

        /* APPROVE DOC */
        case 'APPROVE_FILE_PENDING':
            return {
                ...state,
                request: { ...state.request, pending: true, error: false, fulfilled: false, loading: false }
            };
        case 'APPROVE_FILE_REJECTED':
            return {
                ...state,
                request: { ...state.request, pending: false, error: true, fulfilled: false, loading: false },
            };
        case 'APPROVE_FILE_FULFILLED':
            return {
                ...state,
                request: { ...state.request, pending: false, error: false, fulfilled: true, loading: false },
            }
        /* APPROVE DOC END */


        /* REJECT DOC */
        case 'REJECT_FILE_PENDING':
            return {
                ...state,
                request: { ...state.request, pending: true, error: false, fulfilled: false, loading: false }
            };
        case 'REJECT_FILE_REJECTED':
            return {
                ...state,
                request: { ...state.request, pending: false, error: true, fulfilled: false, loading: false },
            };
        case 'REJECT_FILE_FULFILLED':
            return {
                ...state,
                request: { ...state.request, pending: false, error: false, fulfilled: true, loading: false },
            }
        /* REJECT DOC END */
        case 'CLEAN_STORE':
            return {
                ...state,
                ...initalState,
            }
        default:
            return state;
    }
}