import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const viewerClasses = {
    viewer: {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    viewerPage: {
        marginTop: '10px'
    }


};
const useStyles = makeStyles(viewerClasses);

const PDFViewer = ({
    file,
}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
    };

    const classes = useStyles();
    return (
        <div>
            {file && <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                className={classes.viewer}
                options={options}
            >
                {/* <Page pageNumber={pageNumber} /> */}
                {
                    Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                className={classes.viewerPage}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                            />
                        ),
                    )
                }
            </Document>}
            {/* <p>{pageNumber} od {numPages}</p> */}
        </div>
    );
}

export default PDFViewer;