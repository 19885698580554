import React, { useEffect, useState } from "react";
// @material-ui/icons

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";

import { connect } from "react-redux";
import moment from "moment";
import DocumentInspector from "views/DocumentInspector/DocumentInspector";
import { rejectFile } from "actions/files";
import { approveFile } from "actions/files";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { listTypes } from "actions/types";
import { listStatus } from "actions/status";
import { listCategories } from "actions/categories";
import { listArchive } from "actions/files";
import debounce from 'lodash.debounce';

const documentClasses = {
    documentSearch: {
        transform: "translate3d(0, -10px, 0)",
    }
};
const useStyles = makeStyles(documentClasses);

const Documents = ({ ...props }) => {
    const [activeFile, setActiveFile] = useState();
    const [activeType, setActiveType] = useState(0);
    const [activeStatus, setActiveStatus] = useState(0);
    const [activeCategory, setActiveCategory] = useState(0);
    const [searchFilter, setSearchFilter] = useState('');
    const [numberOfRows, setNumberOfRows] = React.useState(10);
    const [pageSelect, handlePageSelect] = React.useState(0);

    useEffect(() => {
        props.listCategories();
        props.listTypes();
        props.listArchive(undefined, undefined, undefined, undefined, undefined, undefined, numberOfRows, pageSelect);
        props.listStatus();
    }, []);

    useEffect(() => {
        props.listArchive(activeStatus, activeType, activeCategory, searchFilter, undefined, numberOfRows, pageSelect);

    }, [activeCategory, activeType, searchFilter, activeStatus]);


    const inspectDocument = (file) => {
        setActiveFile(file);
    }

    const setSearchFilterHandler = (event) => {
        setSearchFilter(event.target.value);
    };


    const debounceEventHandler = (...args) => {
        const debounced = debounce(...args)
        return (e) => {
            e.persist()
            return debounced(e)
        }
    }

    const onTypeChange = event => {
        setActiveType(event.target.value);
    };

    const onStatusChange = event => {
        setActiveStatus(event.target.value);
    };

    const onCategoryChange = event => {
        setActiveCategory(event.target.value);
    };

    const downloadDocument = (file) => {
        window.location.href = file.address;
    }

    const closeInspectorHandler = () => {
        setActiveFile(undefined);
    }

    // const actionDoneCallBack = () => {
    //     props.loadMyPendingFiles();
    //     closeInspectorHandler();
    // }

    const classes = useStyles();
    const { activeUser, pagination } = props;
    console.log(pagination);
    return (
        <>
            <GridContainer>
                <GridItem xs={4} sm={2} md={3} lg={4}>
                    <FormControl fullWidth >
                        <CustomInput

                            labelText="Pretraga po nazivu"

                            value={searchFilter}
                            id="disabled"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.documentSearch
                            }}
                            inputProps={{
                                onChange: debounceEventHandler(setSearchFilterHandler, 600, { trailing: true, leading: false }),
                            }}
                        />
                    </FormControl>
                </GridItem>
                <GridItem xs={3} sm={2} md={3} lg={3}>
                    <FormControl fullWidth >
                        <InputLabel
                            htmlFor="type-select"
                        >
                            Filter po tipu
                        </InputLabel>
                        <Select
                            value={activeType}
                            onChange={onTypeChange}
                            inputProps={{
                                name: "typeSelect",
                                id: "type-select"
                            }}
                        >
                            <MenuItem
                                value={0}
                            >
                                Svi
                            </MenuItem>
                            {props.typesList?.map((t) => <MenuItem value={t.id}>{t.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem xs={3} sm={2} md={3} lg={3}>
                    <FormControl fullWidth >
                        <InputLabel
                            htmlFor="category-select"
                        >
                            Filter po kategoriji
                        </InputLabel>
                        <Select
                            value={activeCategory}
                            onChange={onCategoryChange}
                            inputProps={{
                                name: "categoryselect",
                                id: "category-select"
                            }}
                        >
                            <MenuItem
                                value={0}
                            >
                                Svi
                            </MenuItem>
                            {props.categoriesList?.map((cat) => <MenuItem value={cat.id}>{cat.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem xs={3} sm={2} md={3} lg={2}>
                    <FormControl fullWidth >
                        <InputLabel
                            htmlFor="status-select"
                        >
                            Filter po statusu
                        </InputLabel>
                        <Select
                            value={activeStatus}
                            onChange={onStatusChange}
                            inputProps={{
                                name: "statusselect",
                                id: "status-select"
                            }}
                        >
                            <MenuItem
                                value={0}
                            >
                                Svi
                            </MenuItem>
                            {props.statusList?.map((st) => <MenuItem value={st.id}>{st.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </GridItem>
            </GridContainer>
            <DocumentInspector closeInspector={closeInspectorHandler} isOpen={!!activeFile} file={activeFile} company={activeUser?.company} />
            <ReactTable
                totalPages={pagination?.last_page}
                numberOfRows={numberOfRows}
                setNumberOfRows={setNumberOfRows}
                pageSelect={pageSelect}
                handlePageSelect={handlePageSelect}
                data={props?.filesData?.map((file, key) => {
                    return {
                        id: file.id,
                        designation: file.protocol.designation,
                        subject: file.protocol.subject,
                        sender: file.protocol.sender,
                        uploader: `${file.uploader?.firstName} ${file.uploader?.lastName}`,
                        name: file.name,
                        createdAt: moment(file.createdAt).local().format(`HH:mm:ss YYYY-DD-MM`),
                        note: file.note,
                        // approved: `${file.comments?.length || 0} od ${file.approvers?.length || 0} odobrilo`,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                {/* use this button to add a like kind of action */}
                                <Button
                                    style={{ padding: '10px 20px' }}
                                    onClick={() => downloadDocument(file)}
                                    color="info"
                                    className="like"
                                >
                                    <i className="fas fa-download" ></i>
                                </Button>
                                <Button
                                    style={{ padding: '10px 20px' }}
                                    onClick={() => inspectDocument(file)}
                                    color="info"
                                    className="like"
                                >
                                    Pogledaj
                                </Button>
                            </div>
                        )
                    };
                }) || []}
                columns={[
                    {
                        Header: "Šifra",
                        accessor: "designation",
                        sortable: false,
                        filterable: false
                    },
                    {
                        Header: "Predmet",
                        accessor: "subject",
                        sortable: false,
                        filterable: false
                    },
                    {
                        Header: "Klijent",
                        accessor: "sender",
                        sortable: false,
                        filterable: false
                    },
                    {
                        Header: "Vrij. prot.",
                        width: 50,
                        icon: '',
                        accessor: "createdAt",
                        sortable: false,
                        filterable: false
                    },
                    {
                        Header: "Protokolisao",
                        icon: '',
                        accessor: "uploader",
                        sortable: false,
                        filterable: false
                    },
                    {
                        Header: "Napomena",
                        icon: '',
                        accessor: "note",
                        sortable: false,
                        filterable: false
                    },
                    {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                    }
                ]}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        filesData: state.files.filesData,
        pagination: state.files.pagination,
        selectedFile: state.files.selectedFile,
        errors: state.files.errors,
        loading: state.files.request.loading,
        inError: state.files.request.error,
        fullfiled: state.files.request.fullfiled,
        activeUser: state.auth?.authData.currentUser,
        typesList: state.types?.typesData,
        statusList: state.status?.statusData,
        categoriesList: state.categories?.categoriesData,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        approveFile: (file, comment, callback) => dispatch(approveFile(file, comment, callback)),
        rejectFile: (file, comment, callback) => dispatch(rejectFile(file, comment, callback)),
        listTypes: (callback) => dispatch(listTypes(callback)),
        listStatus: (callback) => dispatch(listStatus(callback)),
        listCategories: (callback) => dispatch(listCategories(callback)),
        listArchive: (type, category, filter, callback, perPage, page) => dispatch(listArchive(type, category, filter, callback, perPage, page)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Documents);