import { getUidFromJWT } from 'helpers/authHelpers';
import { auth, users, me } from '../api';

export const login = (username, password) => {
	const res = auth.login(username, password)
	return (dispatch) => {
		let response = dispatch({
			type: 'LOGIN',
			payload: res,
		});
	}
}

export const logout = () => {
	return {
		type: 'LOGOUT',
		payload: undefined
	}
}

export const getCurrentUser = () => {
	const res = me.profile()
	return {
		type: 'GET_CURRENT_USER',
		payload: res,
	}
}

export const clearAuthErrors = (uid) => {
	return {
		type: 'CLEAR_AUTH_ERRORS',
	}
}