import { categories } from '../api';

export const listCategories = (callback) => {
    const res = categories.list()

    res.then(() => {
        if (callback) callback();
    });

    return {
        type: 'GET_CATEGORIES',
        payload: res,
    };
}