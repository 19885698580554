import React, { useEffect, useState } from "react";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";

import { dataTable } from "variables/general.js";
import { connect } from "react-redux";
import { loadMyPendingFiles } from "actions/files";
import moment from "moment";
import DocumentInspector from "views/DocumentInspector/DocumentInspector";
import { rejectFile } from "actions/files";
import { approveFile } from "actions/files";

const ReactTables = ({ ...props }) => {
  const [activeFile, setActiveFile] = useState();

  useEffect(() => {
    if (!props.filesData && !props.fullfiled) {
      props.loadMyPendingFiles();
    }

  }, [props.filesData]);

  const inspectDocument = (file) => {
    setActiveFile(file);
  }

  const closeInspectorHandler = () => {
    setActiveFile(undefined);
  }

  const actionDoneCallBack = () => {
    props.loadMyPendingFiles();
    closeInspectorHandler();
  }

  const approveHandler = (file, comment) => {
    props.approveFile(file, comment, actionDoneCallBack)
  }

  const rejectHandler = (file, comment) => {
    props.rejectFile(file, comment, actionDoneCallBack)
  }

  const { activeUser } = props;
  return (
    <>
      <DocumentInspector closeInspector={closeInspectorHandler} isOpen={!!activeFile} file={activeFile} approveFile={approveHandler} rejectFile={rejectHandler} company={activeUser?.company} />
      <ReactTable
        data={props?.filesData?.filter(f => {
          if (f?.comments && f?.comments.some(c => c.author.id === props.activeUser.id)) {
            return false;
          }
          return true
        })?.map((file, key) => {
          return {
            id: file.id,
            designation: file.protocol.designation,
            subject: file.protocol.subject,
            sender: file.protocol.sender,
            uploader: `${file.uploader?.firstName} ${file.uploader?.lastName}`,
            name: file.name,
            createdAt: moment(file.createdAt).local().format(`HH:mm:ss YYYY-DD-MM`),
            note: file.note,
            // approved: `${file.comments?.length || 0} od ${file.approvers?.length || 0} odobrilo`,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a like kind of action */}
                <Button
                  style={{ padding: '10px 20px' }}
                  onClick={() => inspectDocument(file)

                  }
                  color="info"
                  className="like"
                >
                  Obradi
                </Button>{" "}
              </div>
            )
          };
        }) || []}
        columns={[
          {
            Header: "Šifra",
            accessor: "designation",
            sortable: false,
            filterable: false
          },
          {
            Header: "Predmet",
            accessor: "subject",
            sortable: false,
            filterable: false
          },
          {
            Header: "Klijent",
            accessor: "sender",
            sortable: false,
            filterable: false
          },
          {
            Header: "Vrij. prot.",
            width: 50,
            icon: '',
            accessor: "createdAt",
            sortable: false,
            filterable: false
          },
          {
            Header: "Protokolisao",
            icon: '',
            accessor: "uploader",
            sortable: false,
            filterable: false
          },
          // {
          //   Header: "Status",
          //   accessor: "approved",
          //   sortable: false,
          //   filterable: false
          // },
          {
            Header: "Napomena",
            icon: '',
            accessor: "note",
            sortable: false,
            filterable: false
          },
          {
            Header: "",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    filesData: state.files.filesData,
    selectedFile: state.files.selectedFile,
    errors: state.files.errors,
    loading: state.files.request.loading,
    inError: state.files.request.error,
    fullfiled: state.files.request.fullfiled,
    activeUser: state.auth?.authData.currentUser
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadMyPendingFiles: (page, perPage) => dispatch(loadMyPendingFiles(page, perPage)),
    approveFile: (file, comment, callback) => dispatch(approveFile(file, comment, callback)),
    rejectFile: (file, comment, callback) => dispatch(rejectFile(file, comment, callback)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReactTables);