import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import MomentUtils from '@date-io/moment';

// redux
import { Provider } from 'react-redux';
import store from './store/store';

// core components
import Admin from "layouts/Admin.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";
import "assets/css/nextjs-material-dashboard-pro.css";
import "assets/css/material-dashboard-pro-react.css";
import "assets/scss/nextjs-material-dashboard-pro.scss";
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const hist = createBrowserHistory();

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Provider store={store}>
      <Router history={hist}>
        <Switch>
          <Route path="/" component={Admin} />
          {/* <Redirect from="/" to="/users" /> */}
        </Switch>
      </Router>
    </Provider>
  </MuiPickersUtilsProvider>
  ,
  document.getElementById("root")
);
