const initalState = {
    request: { pending: false, error: false, fulfilled: false, loading: false },
    categoriesData: null,
    selectedType: undefined,
    errors: {},
};

export default function reducer(state = initalState, action) {
    switch (action.type) {
        /* GET TYPES */
        case 'GET_STATUS_PENDING':
            return {
                ...state,
                request: { ...state.request, pending: true, error: false, fulfilled: false, loading: false }
            };
        case 'GET_STATUS_REJECTED':
            return {
                ...state,
                request: { ...state.request, pending: false, error: true, fulfilled: false, loading: false },
                statusData: []
            };
        case 'GET_STATUS_FULFILLED':
            return {
                ...state,
                request: { ...state.request, pending: false, error: false, fulfilled: true, loading: false },
                statusData: action.payload
            }
        /* GET TYPES END */
        case 'CLEAN_STORE':
            return {
                ...state,
                ...initalState,
            }
        default:
            return state;
    }
}